/**
 * Created by Jongjin on 29.08.2017.
 */

import {Component} from '@angular/core';

@Component({
    selector: 'mn-jsme-test',
    template: `
            <h2>Hello</h2><mn-jsme [height]="400"></mn-jsme>
            <br/>
    `,
})
export class MnJsmeTest {
    constructor() {}
}
