/**
 * Created by Jongjin on 8/29/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { MnJsmeTest } from './MnJsmeTest'
import {MnJsme} from "./MnJsme";
import {MnJsmeService} from './MnJsmeService'

@NgModule({
    imports: [],
    exports: [MnJsme],
    declarations: [
        MnJsmeTest, MnJsme
    ],
    entryComponents: [
        MnJsmeTest, MnJsme
    ]
})
export class MnJsmeModule {
    static forRoot(): MnJsmeModule {
        return {
            ngModule: MnJsmeModule,
            providers: [MnJsmeService]
        };
    }
}

export class mn_jsme {
    static configure():any {
        return [
            MnJsmeModule.forRoot(),
        ];
    }
}