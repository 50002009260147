"use strict";

// angular2
import {Component,Input,Output,EventEmitter} from "@angular/core";

// jsme
import {MnJsmeService} from "./MnJsmeService"

declare var JSApplet:any;

@Component({
	selector: "mn-jsme",
	moduleId: module.id,
	template: "<div id='{{mId}}' #jsmeTag></div>"
})
export class MnJsme {

	mId:string = "";
	mHeight:number = 300;
	mWidth:number = 400;
	mJSME:any = null;
	mSmiles = "";

	/*mMol = "";
	@Input('mol') set mol(value) {
		this.mMol = value;
		if (this.mJSME == null) return;
		this.mJSME.readMolFile(value);
	}*/
	@Input('height') set height(value) {
		this.mHeight = value;
		if (this.mJSME == null) return;
		this.mJSME.setHeight(this.mHeight+'px');
	}
	@Input('width') set width(value) {
		this.mWidth = value;
		if (this.mJSME == null) return;
		this.mJSME.setWidth(this.mWidth+'px');
	}
	@Output('smilesChange') mSmilesChange = new EventEmitter();

	constructor(private mJsmeService:MnJsmeService) {
		this.mId = 'jsme_' + this.mJsmeService.createId();
	}

	ngAfterViewInit() {
		var me = this;
		this.mJsmeService.createEditor(()=>{
			me.mJSME = new JSApplet.JSME(this.mId,this.mWidth+'px',this.mHeight+'px');
			me.mJSME.setAfterStructureModifiedCallback(function(jsme_event){ me.onStructureChange(jsme_event); });
		});
	}

	ngOnDestroy() {
		if (this.mJSME !== null) delete this['mJSME'];
		this.mJSME = null;
	}

	onStructureChange(jsme_event) {
		this.mSmiles = jsme_event.src.smiles();
		this.mSmilesChange.emit(this.mSmiles);
	}

	public setMol(mol) {
		console.log("JSME",mol);
		this.mJSME.readMolFile(mol);
	}

	public smiles() {
		if (this.mJSME !== null) return this.mJSME.smiles();
	}

	public mol2000() {
		if (this.mJSME !== null) return this.mJSME.molFile(false);
	}

	public mol3000() {
		if (this.mJSME !== null) return this.mJSME.molFile(true);
	}

	public jme() {
		if (this.mJSME !== null) return this.mJSME.jmeFile();
	}

}
