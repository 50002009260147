"use strict";

// angular2
import {Injectable} from '@angular/core';


// avoid error message: error TS2339: Property 'jsmeOnLoad' does not exist on type 'Window'
declare global {
    interface Window { jsmeOnLoad: any; }
}

// avoid error message: error TS2304: Cannot find name 'System'
declare const System: any;

// jsme instance counter
//let jsme_id_count = 0;

// list of functions to be performed right after the JSME library is loaded and ready
let jsme_callbacks: (() => void)[] = [];


@Injectable()
export class MnJsmeService {

    //private mCallbacks = [];

    constructor() {
        var me = this;
        window.jsmeOnLoad = function () {
            me.onJsmeLibraryLoaded()
        };
        System.import('jsme').then(jsme => {
            console.log('JSME loading', jsme);
        });
        console.log('Outside JSME loading');
    }

    public createId() {
        //jsme_id_count++;
        //return jsme_id_count;
        return Math.abs(Date.now()-1526552327520) + '_' + parseInt(''+ (Math.random()*10000));
    }

    public addCallback(callback: (() => void)): void {
        if (jsme_callbacks === null) {
            callback();
            return;
        }
        jsme_callbacks.push(callback);
    }

    public createEditor(callback) {
        this.addCallback(callback);
        /*if (jsme_callbacks === null) {
            callback();
            return;
        }
        jsme_callbacks.push(callback);*/
    }

    private onJsmeLibraryLoaded() {
        //console.log('JSME loaded');
        for (let i = 0, l = jsme_callbacks.length; i < l; i++) {
            jsme_callbacks[i]();
        }
        jsme_callbacks = null;
    }


}
